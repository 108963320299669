<template>
  <div class='profile-tabs'>
    <button type="button" @click="toggleView('watchlist')" :class="{ 'active-tab': this.view === 'watchlist'}">Watchlist</button>
    <button type="button" @click="toggleView('favorites')" :class="{ 'active-tab': this.view === 'favorites'}">Favorites</button>
    <button type="button" @click="toggleView('settings')" :class="{ 'active-tab': this.view === 'settings'}">Settings</button>
    <!-- <input type="text" placeholder="Search Users" @keyup.enter="searchUsers()" v-model="usernameSearch"> -->
  </div>

  <div class="profile">
    <!-- Watchlists -->
    <template v-if="view === 'watchlist'">
      <!-- Movie Watchlist -->
      <section class="profile__section">
        <!-- Heading -->
        <h3 class="profile__heading" v-if="watchlist.movies.length">Movies <span class="limit">({{ watchlist.movies.length }}/25)</span></h3>
        <!-- Movies -->
        <div class="horizontal-wrapper">
          <poster
            v-for="(film, index) in watchlist.movies"
            :key='index'
            :item='film'
            design='poster'
            type='movie'
          />
        </div>
      </section>

      <!-- Show Watchlist -->
      <section class="profile__section">
        <!-- Heading -->
        <h3 class="profile__heading" v-if="watchlist.shows.length">Shows <span class="limit">({{ watchlist.shows.length }}/25)</span></h3>
        <!-- Shows -->
        <div class="horizontal-wrapper">
          <poster
            v-for="(film, index) in watchlist.shows"
            :key='index'
            :item='film'
            design='poster'
            type='show'
          />
        </div>
      </section>
    </template>
    <!-- Watchlists End -->

    <!-- Favorites -->
    <template v-if="view === 'favorites'">
      <!-- Favorite Movies -->
      <section class="profile__section">
        <!-- Heading -->
        <h3 class="profile__heading" v-if="favorites.movies.length">Movies <span class="limit">({{ favorites.movies.length }}/10)</span></h3>
        <!-- Movies -->
        <div class="horizontal-wrapper">
          <poster
            v-for="(film, index) in favorites.movies"
            :key='index'
            :item='film'
            design='poster'
            type='movie'
          />
        </div>
      </section>

      <!-- Favorite Shows -->
      <section class="profile__section">
        <!-- Heading -->
        <h3 class="profile__heading" v-if="favorites.shows.length">Shows <span class="limit">({{ favorites.shows.length }}/10)</span></h3>
        <!-- Shows -->
        <div class="horizontal-wrapper">
          <poster
            v-for="(film, index) in favorites.shows"
            :key='index'
            :item='film'
            design='poster'
            type='show'
          />
        </div>
      </section>

      <!-- Favorite Stars -->
      <section class="profile__section">
        <!-- Heading -->
        <h3 class="profile__heading" v-if="favorites.stars.length">Stars <span class="limit">({{ favorites.stars.length }}/10)</span></h3>
        <!-- Stars -->
        <div class="horizontal-wrapper">
          <poster
            v-for="(person, index) in favorites.stars"
            :key='index'
            :item='person'
            design='poster'
            type='person'
          />
        </div>
      </section>
    </template>
    <!-- Favorites End -->
  </div>
  <!-- Profile End -->

  <div class="container settings" v-if="view === 'settings'">
    <h3 class="profile__heading">Watched Movies: {{ watched.movies.length }}</h3>

    <label for="username">Username</label>
    <p v-if="error.usernameTooShort">Username must be at least 3 characters long.</p>
    <p v-if="error.usernameInUse">Username is already taken.</p>
    <input type="text" name="username" v-model="user.username">
    <button class="primary small" type="button" @click="changeUsername">Change Username</button>
    <!-- <label for="email">Email</label>
    <input type="email" name="email" v-model="user.email"> -->
    <!-- <button class="primary small" type="button" @click="signOut">Change Email</button> -->
    <!-- <label for="password">Password</label>
    <p v-if="error.passwordTooShort">Password must be at least 6 characters long.</p>
    <input type="text" name="password" placeholder="&#9679;&#9679;&#9679;&#9679;&#9679;" v-model="password">
    <button class="primary small" type="button" @click="changePassword">Change Password</button> -->
    <button  class="small" type="button" @click="signOut">Sign out</button>
  </div>
</template>

<script>
  import api from '@/api'
  import { defineAsyncComponent } from 'vue'
  import { mapState, mapGetters, mapActions } from 'vuex'
  import { getAuth, updatePassword } from "firebase/auth";
  import { getFirestore, getDocs, query, updateDoc, doc, collection, where } from "firebase/firestore";
  import { getAnalytics, logEvent } from "firebase/analytics";

  export default {
    name: 'Profile',
    data: () => ({
      view: 'watchlist',
      password: '',
      usernameSearch: '',
      foundUser: {},
      error: {
        usernameInUse: false,
        usernameTooShort: false,
        passwordTooShort: false
      }
    }),
    components: {
      Poster: defineAsyncComponent(() => import('@/components/poster' /* webpackChunkName: 'Poster' */))
    },
    computed: {
      ...mapState('user', ['user', 'watchlist', 'favorites', 'watched']),
      ...mapGetters('user', {
        movieWatchlist: 'getMovieWatchlist',
        showWatchlist: 'getShowWatchlist',
        favoriteMovies: 'getFavoriteMovies',
        favoriteShows: 'getFavoriteShows',
        favoriteStars: 'getFavoriteStars'
       })
    },
    created() {
      this.movieWatchlist.forEach(movie => {
        // If movie already exists, don't request it again
        if (this.watchlist.movies.find(x => x.id === movie)) return
        // Request movie
        this.getItem({ type: 'movie', category: 'movies', id: movie})
      })
      this.showWatchlist.forEach(show => {
        // If show already exists, don't request it again
        if (this.watchlist.shows.find(x => x.id === show)) return
        // Request show
        this.getItem({ type: 'tv', category: 'shows', id: show})
      })
      this.favoriteMovies.forEach(movie => {
        // If show already exists, don't request it again
        if (this.favorites.movies.find(x => x.id === movie)) return
        // Request show
        this.getFavoriteItem({ type: 'movie', category: 'movies', id: movie})
      })
      this.favoriteShows.forEach(show => {
        // If show already exists, don't request it again
        if (this.favorites.shows.find(x => x.id === show)) return
        // Request show
        this.getFavoriteItem({ type: 'tv', category: 'shows', id: show})
      })
      this.favoriteStars.forEach(star => {
        // If show already exists, don't request it again
        if (this.favorites.stars.find(x => x.id === star)) return
        // Request show
        this.getFavoriteStars({ type: 'person', category: 'stars', id: star})
      })
      // this.user.movieWatchlist.forEach(movie => {
      //   // If movie already exists, don't request it again
      //   if (this.watchlist.movies.find(x => x.id === movie)) return
      //   // Request movie
      //   this.getItem({ type: 'movie', category: 'movies', id: movie})
      // })
      // this.user.showWatchlist.forEach(show => {
      //   // If show already exists, don't request it again
      //   if (this.watchlist.shows.find(x => x.id === show)) return
      //   // Request show
      //   this.getItem({ type: 'tv', category: 'shows', id: show})
      // })
    },
    methods: {
      ...mapActions('user', ['signOut']),

      getItem({ type, category, id }) {
        api.tmdb.reqItem('film', type, id).then(res => this.$store.commit('user/SET_WATCHLIST', { category , val: res.data }))
      },

      getFavoriteItem({ type, category, id }) {
        api.tmdb.reqItem('film', type, id).then(res => this.$store.commit('user/SET_FAVORITES', { category , val: res.data }))
      },

      getFavoriteStars({ type, category, id }) {
        api.tmdb.reqItem('person', type, id).then(res => this.$store.commit('user/SET_FAVORITES', { category , val: res.data }))
      },

      toggleView(view) {
        this.view = view
        this.$store.commit('user/SET_ALERT')
      },

      changeUsername() {
        // Verify username length
        this.checkUsernameLength()
      },

      checkUsernameLength() {
        // Remove whitespace and spaces from username
        let cleanUsername = this.user.username.replace(/\s+/g, '')
        // Verify username is at least 3 characters long
        if (cleanUsername.length < 3) {
          this.error.usernameTooShort = true
        } else {
          this.error.usernameTooShort = false

          this.checkIfUsernameExists()
        }
      },

      async checkIfUsernameExists() {
        // Remove whitespace and spaces from username
        let cleanUsername = this.user.username.replace(/\s+/g, '')
        // Query database for matching username
        let username = await getDocs(query(collection(getFirestore(), 'Users'), where('username', '==', cleanUsername)))
        // Manage username error
        if (username.empty) {
          this.error.usernameInUse = false

          const auth = getAuth();
          const user = auth.currentUser;

          updateDoc(doc(getFirestore(), 'Users', user.uid), {
            username: this.user.username
          }).then(() => {
            this.$store.commit('user/SET_ALERT', { type: 'success', message: 'Your username has been updated'})
          }).catch((error) => {
            console.log(error)
          });

        } else {
          this.error.usernameInUse = true
        }
      },

      async searchUsers() {
        // Remove whitespace and spaces from username
        let cleanUsername = this.usernameSearch.replace(/\s+/g, '')
        // Query database for matching username
        let response = await getDocs(query(collection(getFirestore(), 'Users'), where('username', '==', cleanUsername)))
        // Manage found user
        // response.empty ? this.foundUser = {} : await response.forEach(doc => this.foundUser = doc.data())

        if (!response.empty) {
          response.forEach(doc => {
            this.foundUser.username = doc.data()['username']
            this.foundUser.favoriteMovies = doc.data()['favoriteMovies']
            this.foundUser.favoriteShows = doc.data()['favoriteShows']
            this.foundUser.favoriteStars = doc.data()['favoriteStars']
            this.foundUser.movieWatchlist = doc.data()['movieWatchlist']
            this.foundUser.showWatchlist = doc.data()['showWatchlist']
          })
        }
      },

      // Can require recent login
      changePassword() {
        const auth = getAuth();
        const user = auth.currentUser;

        if (this.password.length < 3) {
          this.error.passwordTooShort = true
        } else {
          this.error.passwordTooShort = false
          updatePassword(user, this.password).then(() => {
            this.$store.commit('user/SET_ALERT', { type: 'success', message: 'Your password has been updated'})
            this.password = ''

            logEvent(getAnalytics(), 'updated_username', {
              user: this.username,
             })

          }).catch((error) => {
            console.log(error)
          });
        }
      }
    },
    deactivated() {
      this.$store.commit('user/SET_ALERT')
    }
  }
</script>
